import axios from 'axios';
import { decodeJWT } from './jwt';  // Assumed this function decodes the JWT to extract the expiry timestamp

// Setting up the base URL based on the environment
const hostname = window.location.hostname;
const environments = {
	development: ['localhost', '127.0.0.1'],
	staging: 'staging.semactic.com',
	testing: 'testing.semactic.com',
	production: 'django.semactic.com',
};

let baseURL = 'https://django.semactic.com/api/';

if (environments.development.includes(hostname)) {
	baseURL = 'http://127.0.0.1:8000/api/';
} else if (hostname.includes(environments.staging)) {
	baseURL = 'https://django-staging.semactic.com/api/';
} else if (hostname.includes(environments.testing)) {
	baseURL = 'https://django-testing.semactic.com/api/';
}

axios.defaults.baseURL = baseURL;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
	failedQueue.forEach(prom => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	});

	failedQueue = [];
};

const getProjectId = () => {
	const project = JSON.parse(localStorage.getItem('market'));
	if (!project) {
		return null;
	}
	return project.selectedProject;
};

// Function to update the user role in localStorage and reload if it has changed
const updateRole = role => {
	const user = JSON.parse(localStorage.getItem('auth'));

	if (user && user.role !== role) {
		user.role = role;
		user.access = getRoleName(role);
		localStorage.setItem('auth', JSON.stringify(user));
		location.reload();
	}
};

// Function to map role IDs to role names
const getRoleName = role => {
	switch (role) {
		case 262144:
			return 'super_admin';
		case 1:
			return 'admin';
		case 8192:
			return 'project manager';
		case 32768:
			return 'digital specialist';
		case 65536:
			return 'digital enabler';
		case 2048:
			return 'partner';
		default:
			return 'guest';
	}
};

const sendRefresh = async () => {
	const response = await axios.post(
		'refresh',
		{ project_id: getProjectId() },
		{ withCredentials: true }
	);

	if (response.status === 200) {
		const { token, role } = response.data;

		const decodedToken = decodeJWT(token);
		decodedToken.token = token;

		localStorage.setItem('decode', JSON.stringify(decodedToken));
		updateRole(role);

		return token;
	}

	return null;
}

axios.interceptors.request.use(
	async (config) => {

		// Avoid the refresh request from being intercepted by itself
		const unprotectedEndpoints = ['refresh', 'login'];

		if (unprotectedEndpoints.includes(config.url)) {
			return config;
		}

		const token = JSON.parse(localStorage.getItem('decode'));
		if (token) {

			// If the token is not in the headers, add it
			if (!config.headers['Authorization']) {
				axios.defaults.headers.common['Authorization'] = `Bearer ${token.token}`;
				config.headers['Authorization'] = `Bearer ${token.token}`;
			}

			// Check if the token is expired
			const currentTime = Math.floor(Date.now() / 1000);
			if (token.exp < currentTime) {
				console.log('Token expired');

				if (!isRefreshing) {
					isRefreshing = true;

					try {
						await sendRefresh().then(token => {
							axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
							config.headers['Authorization'] = `Bearer ${token}`;
							isRefreshing = false;
						});
					}
					catch (error) {
						console.log('Error refreshing token:', error);
					}
				}
			}
		}

		// if there is no token in the headers, send a refresh request
		if (!config.headers['Authorization']) {
			if (!isRefreshing) {
				isRefreshing = true;

				try {
					await sendRefresh().then(token => {
						axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
						config.headers['Authorization'] = `Bearer ${token}`;
						isRefreshing = false;
					});
				}
				catch (error) {
					console.log('Error refreshing token:', error);
				}
			}
		}

		return config;
	},
	(error) => {
		console.log('Error during request setup:', error);
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	response => response,
	async error => {
		const originalRequest = error.config;

		if (error.response && error.response.status === 401) {
			if (error.response.config.url === 'refresh' && error.response.data.detail === 'unauthenticated') {
				window.location.href = '/logout';
				return Promise.reject(error);
			}

			if (!isRefreshing) {
				isRefreshing = true;
				return new Promise(function (resolve, reject) {
					axios
						.post(
							'refresh',
							{
								project_id: getProjectId(),
							},
							{ withCredentials: true },
						)
						.then(({ status, data }) => {
							if (status === 200) {
								axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
								originalRequest.headers['Authorization'] = `Bearer ${data.token}`;

								const decodedToken = decodeJWT(data.token);
								decodedToken.token = data.token;
								localStorage.setItem('decode', JSON.stringify(decodedToken));

								updateRole(data.role);

								processQueue(null, data.token);
								resolve(axios(originalRequest));
							}
						})
						.catch(err => {
							processQueue(err, null);
							window.location.href = '/logout';
							reject(err);
						})
						.then(() => {
							isRefreshing = false;
						});
				});
			} else {
				return new Promise(function (resolve, reject) {
					failedQueue.push({ resolve, reject });
				})
					.then(token => {
						originalRequest.headers['Authorization'] = 'Bearer ' + token;
						return axios(originalRequest);
					})
					.catch(err => Promise.reject(err));
			}
		}

		return Promise.reject(error);
	},
);